


























































































































































































































































import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import AutoNumericUtil from '@/utilities/AutoNumericUtil';
import CommonUtil from '@/utilities/CommonUtil';
import AutoNumeric, { Options } from 'autonumeric';
import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerGroupInfoProperties } from './CustomerGroupInfoView.vue';

@Component({
    data: () => ({
        fullNamePicker: false,
        messagePicker: false,
        backgroundPicker: false,
        tagPicker: false
    })
})
export default class CustomerGroupMainView extends Vue {
    @Prop() private properties: CustomerGroupInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private inputOrderExtendExpires: HTMLInputElement;
    private inputDepositExtendExpires: HTMLInputElement;
    private inputCheckoutExtendExpires: HTMLInputElement;
    private inputCancelledExtendExpires: HTMLInputElement;
    private inputOrderExtendCount: HTMLInputElement;
    private inputDepositExtendCount: HTMLInputElement;
    private inputCheckoutExtendCount: HTMLInputElement;
    private inputCancelledExtendCount: HTMLInputElement;
    private inputPurchaseAmountLimit: HTMLInputElement;
    private inputPurchaseQuantityLimit: HTMLInputElement;

    public get customerGroup() {
        return this.properties.customerGroup;
    }

    public get codeEditable() {
        return this.customerGroup.code !== "-----";
    }

    public get orderExtendExpiresEnabled() {
        return this.properties.orderExtendExpiresEnabled;
    }

    public set orderExtendExpiresEnabled(v: boolean) {
        this.properties.orderExtendExpiresEnabled = v;
    }

    public get orderExtendExpires() {
        if (this.orderExtendExpiresEnabled) {
            return this.properties.orderExtendExpires;
        } else {
            return this.$t('text.disabled');
        }
    }

    public set orderExtendExpires(v: any) {
        this.properties.orderExtendExpires = v;
    }

    public get depositExtendExpiresEnabled() {
        return this.properties.depositExtendExpiresEnabled;
    }

    public set depositExtendExpiresEnabled(v: boolean) {
        this.properties.depositExtendExpiresEnabled = v;
    }

    public get depositExtendExpires() {
        if (this.depositExtendExpiresEnabled) {
            return this.properties.depositExtendExpires;
        } else {
            return this.$t('text.disabled');
        }
    }

    public set depositExtendExpires(v: any) {
        this.properties.depositExtendExpires = v;
    }

    public get checkoutExtendExpiresEnabled() {
        return this.properties.checkoutExtendExpiresEnabled;
    }

    public set checkoutExtendExpiresEnabled(v: boolean) {
        this.properties.checkoutExtendExpiresEnabled = v;
    }

    public get checkoutExtendExpires() {
        if (this.checkoutExtendExpiresEnabled) {
            return this.properties.checkoutExtendExpires;
        } else {
            return this.$t('text.disabled');
        }
    }

    public set checkoutExtendExpires(v: any) {
        this.properties.checkoutExtendExpires = v;
    }

    public get cancelledExtendExpiresEnabled() {
        return this.properties.cancelledExtendExpiresEnabled;
    }

    public set cancelledExtendExpiresEnabled(v: boolean) {
        this.properties.cancelledExtendExpiresEnabled = v;
    }

    public get cancelledExtendExpires() {
        if (this.cancelledExtendExpiresEnabled) {
            return this.properties.cancelledExtendExpires;
        } else {
            return this.$t('text.disabled');
        }
    }

    public set cancelledExtendExpires(v: any) {
        this.properties.cancelledExtendExpires = v;
    }

    public get fullNameStyle() {
        return this.makeColorStyle(
            this.customerGroup.fullNameColor,
            this.$data.fullNamePicker ? "50%" : "4px"
        );
    }

    public get messageStyle() {
        return this.makeColorStyle(
            this.customerGroup.messageColor,
            this.$data.messagePicker ? "50%" : "4px"
        );
    }

    public get backgroundStyle() {
        return this.makeColorStyle(
            this.customerGroup.backgroundColor,
            this.$data.backgroundPicker ? "50%" : "4px"
        );
    }

    public get tagStyle() {
        return this.makeColorStyle(
            this.customerGroup.tagColor,
            this.$data.tagPicker ? "50%" : "4px"
        );
    }

    public makeColorStyle(background: string, borderRadius: string) {
        return {
            background,
            cursor: "pointer",
            height: "25px",
            width: "25px",
            borderRadius,
            transition: "border-radius 200ms ease-in-out"
        };
    }

    public orderExtendExpiresEnabledChanged() {
        if (this.orderExtendExpiresEnabled) {
            this.orderExtendExpires = 1;
            this.updateFormatter();
        } else {
            AutoNumericUtil.remove(this.inputOrderExtendExpires);
        }
    }

    public orderExtendExpiresBlur() {
        var n1 = AutoNumericUtil.find(this.inputOrderExtendExpires);
        if (n1 && n1.getNumber() <= 0) {
            this.orderExtendExpires = 1;
            n1.set(1);
        }
    }

    public depositExtendExpiresEnabledChanged() {
        if (this.depositExtendExpiresEnabled) {
            this.depositExtendExpires = 1;
            this.updateFormatter();
        } else {
            AutoNumericUtil.remove(this.inputDepositExtendExpires);
        }
    }

    public depositExtendExpiresBlur() {
        var n1 = AutoNumericUtil.find(this.inputDepositExtendExpires);
        if (n1 && n1.getNumber() <= 0) {
            this.depositExtendExpires = 1;
            n1.set(1);
        }
    }

    public checkoutExtendExpiresEnabledChanged() {
        if (this.checkoutExtendExpiresEnabled) {
            this.checkoutExtendExpires = 1;
            this.updateFormatter();
        } else {
            AutoNumericUtil.remove(this.inputCheckoutExtendExpires);
        }
    }

    public checkoutExtendExpiresBlur() {
        var n1 = AutoNumericUtil.find(this.inputCheckoutExtendExpires);
        if (n1 && n1.getNumber() <= 0) {
            this.checkoutExtendExpires = 1;
            n1.set(1);
        }
    }

    public cancelledExtendExpiresEnabledChanged() {
        if (this.cancelledExtendExpiresEnabled) {
            this.cancelledExtendExpires = 1;
            this.updateFormatter();
        } else {
            AutoNumericUtil.remove(this.inputCancelledExtendExpires);
        }
    }

    public cancelledExtendExpiresBlur() {
        var n1 = AutoNumericUtil.find(this.inputCancelledExtendExpires);
        if (n1 && n1.getNumber() <= 0) {
            this.cancelledExtendExpires = 1;
            n1.set(1);
        }
    }

    public mounted() {
        this.inputOrderExtendExpires = this.$el.querySelector("#order-extend-expires");
        this.inputDepositExtendExpires = this.$el.querySelector("#deposit-extend-expires");
        this.inputCheckoutExtendExpires = this.$el.querySelector("#checkout-extend-expires");
        this.inputCancelledExtendExpires = this.$el.querySelector("#cancelled-extend-expires");
        this.inputOrderExtendCount = this.$el.querySelector("#order-extend-count");
        this.inputDepositExtendCount = this.$el.querySelector("#deposit-extend-count");
        this.inputCheckoutExtendCount = this.$el.querySelector("#checkout-extend-count");
        this.inputCancelledExtendCount = this.$el.querySelector("#cancelled-extend-count");
        this.inputPurchaseAmountLimit = this.$el.querySelector("#purchase-amount-limit");
        this.inputPurchaseQuantityLimit = this.$el.querySelector("#purchase-quantity-limit");

        this.updateFormatter();
        this.properties.events.subscribe('update-formatter', this.updateFormatter);
    }

    public destroyed() {
        this.properties.events.remove('update-formatter', this.updateFormatter);
    }

    public updateFormatter() {
        setTimeout(() => {
            const customerGroup = this.customerGroup;
            const options1: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };
            const options2 = CommonUtil.clone(options1);
            options2.decimalPlaces = 0;

            if (this.orderExtendExpiresEnabled) {
                AutoNumericUtil.findOrNew(this.inputOrderExtendExpires, null, options2)
                    .set(this.orderExtendExpires);
            }
            if (this.depositExtendExpiresEnabled) {
                AutoNumericUtil.findOrNew(this.inputDepositExtendExpires, null, options2)
                    .set(this.depositExtendExpires);
            }
            if (this.checkoutExtendExpiresEnabled) {
                AutoNumericUtil.findOrNew(this.inputCheckoutExtendExpires, null, options2)
                    .set(this.checkoutExtendExpires);
            }
            if (this.cancelledExtendExpiresEnabled) {
                AutoNumericUtil.findOrNew(this.inputCancelledExtendExpires, null, options2)
                    .set(this.cancelledExtendExpires);
            }
            
            AutoNumericUtil.findOrNew(this.inputOrderExtendCount, null, options2)
                .set(customerGroup.orderExtendCount ?? 0);
            AutoNumericUtil.findOrNew(this.inputDepositExtendCount, null, options2)
                .set(customerGroup.depositExtendCount ?? 0);
            AutoNumericUtil.findOrNew(this.inputCheckoutExtendCount, null, options2)
                .set(customerGroup.checkoutExtendCount ?? 0);
            AutoNumericUtil.findOrNew(this.inputCancelledExtendCount, null, options2)
                .set(customerGroup.cancelledExtendCount ?? 0);
            AutoNumericUtil.findOrNew(this.inputPurchaseAmountLimit, null, options1)
                .set(customerGroup.purchaseAmountLimit ?? 0);
            AutoNumericUtil.findOrNew(this.inputPurchaseQuantityLimit, null, options2)
                .set(customerGroup.purchaseQuantityLimit ?? 0);
        }, 150);
    }
}
